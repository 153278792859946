import '../plugins/fancyboxPlugin';

// lightbox init
export default function initFancybox() {
	jQuery('a.lightbox, [data-fancybox]').fancybox({
		parentEl: 'body',
		margin: [50, 0],
		slideShow: false,
		fullScreen: false,
		thumbs: false,
		toolbarCloseBtn: false
	});

	$('a[rel=fancyboxgroup]').fancybox({
		'transitionIn': 'fade',
		'transitionOut': 'fade',
		'speedIn': 600,
		'speedOut': 200,
		'overlayShow': false,
		'type': 'image',
		beforeShow: function () {
			var imgAlt = $(this.element).find("img").attr("alt");
			var dataAlt = $(this.element).data("alt");
			if (imgAlt) {
				$(".fancybox-image").attr("alt", imgAlt);
			} else if (dataAlt) {
				$(".fancybox-image").attr("alt", dataAlt);
			}
		}
	});
}